<template>

  <div>
    <!-- <div v-if="haveHostings"> -->
      <auth-content>
        <el-header :title="$t('modules.hosting.dashboard')" slot="header">
          <el-header-action v-if="isWorldOwner" size="xs" slot="actions" icon="plus3" :label="$t('modules.hosting.create')" @click.native="openModal('hosting-world-add')"></el-header-action>
        </el-header>
        <div v-if='haveHostings'>
          <hosting-teaser v-for="hosting in hostings" v-if="!hosting.parent" :hosting="hosting" />
        </div>
        <el-empty v-else :title="$t(`modules.hosting.empty_title`)" :text="$t(`modules.hosting.empty_text`)" />


        <el-modal size="sm" :title="$t('modules.hosting.create')" id="hosting-world-add">
          <form @submit.prevent="createSpace()">
            <div class="row mb-3">
              <div class="col-12">
                <label for="name">{{ $t('modules.hosting.fields.name.label') }}</label>
                <input v-model="new_name" required type="text" class="form-control" id="name" :placeholder="$t('modules.hosting.fields.name.label')">
              </div>
            </div>
            <div class="text-center">
              <button v-if="!loading" type="submit" class="btn btn-success text-white">{{ $t('common.submit') }}</button>
              <div v-if="loading" class="text-gray pt-1 pb-2"><loader /></div>
            </div>
          </form>
        </el-modal>

      </auth-content>
    <!-- </div> -->
<!--
    <div v-if="isWorldOwner">

      <div v-if="!haveHostings && !loading">
        <auth-content>
          <div class="card p-4">
            <h1 class="text-center">{{ $t('modules.hosting.onboarding.title') }}</h1>
            <div class="row my-4 justify-content-center">
              <div class="col-md-9">
                <div class="mb-3 h3 font-weight-light line" v-for="(line, index) in $t('modules.hosting.onboarding.lines')">
                  <span class="d-inline-block circle text-white mr-2 badge badge-primary">
                    <span class="h3">{{ index + 1 }}</span>
                  </span>
                  <span v-html="line" class="d-block pl-3 ml-4 pt-1"></span>
                </div>
              </div>
            </div>
            <form class="text-center form-new-space mx-auto mb-3" @submit.prevent="createSpace()">
              <input v-model="new_name" required ref="input_new" type="text" class="form-control form-control-lg mb-3" :placeholder="$t('modules.hosting.onboarding.new_placeholder')">
              <button class="btn btn-lg btn-primary text-white p-2 px-4">
                <i class="icon-plus3"></i>
                {{ $t('modules.hosting.onboarding.submit') }}
              </button>
            </form>
          </div>
        </auth-content>
      </div>

      <div v-if="!haveHostings && loading">
        <auth-content>
          <div class="card p-4">
            <h1 class="text-center">{{ $t('modules.hosting.onboarding.title_creation', {name: new_name}) }}</h1>

            <div class="circle progress my-3 mx-auto">
              <div class="circle progress-bar progress-bar-striped progress-bar-animated bg-success" :style="`width: ${new_pct}%`">
                <span class="sr-only">{{new_pct}}%</span>
              </div>
            </div>

          </div>
        </auth-content>
      </div>

    </div>

    <div v-if="!isWorldOwner">

      <div v-if="!haveHostings">
        <auth-content>
          <el-header :title="$t('modules.hosting.dashboard')" slot="header">
          </el-header>
          <el-alert type="warning">
            {{ $t('modules.hosting.no_access_messages') }}
          </el-alert>
        </auth-content>
      </div>

    </div> -->
  </div>

</template>

<script>

import store from '@/store'
import { HOSTING_STORE, HOSTINGS_REQUEST, CURRENT_HOSTINGID_RESET } from '../mutations-types'

export default {
  name: 'Index',
  data () {
    return {
      new_name: '',
      new_pct: 0,
      loading: false,
      hostings: {},
    }
  },
  mounted () {
    // if (!this.haveHostings && this.isWorldOwner) {
    //   this.$refs.input_new.focus()
    // }
  },
  computed: {
    haveHostings () {
      return Object.keys(this.hostings).length > 0
    },
    isWorldOwner () {
      return this.$store.getters['auth/isWorldOwner']
    }
  },
  methods: {
    loadHostings() {
      return this.$store.dispatch('hosting/' + HOSTINGS_REQUEST, this.$route.params.world).then(hostings => {
        this.hostings = {...hostings}
      })
    },
    createSpace () {
      this.loading = true
      this.new_pct = this.new_pct + 10
      setTimeout(() => { this.new_pct = this.new_pct + 40 }, 500);
      setTimeout(() => { this.new_pct = this.new_pct + 30 }, 1000);

      this.$store.dispatch('hosting/' + HOSTING_STORE, {
        name: this.new_name,
        world_id: this.$route.params.world
      }).then(data => {
        this.new_pct = 100
        this.notifSuccess(this.$t('modules.hosting.messages.project_created'))
        this.closeModal('hosting-world-add')
        this.new_name = ''
        this.$router.push({ name: 'hosting.page', params: {
          world: this.$route.params.world,
          hosting: data.id,
        } })
      })
      .catch(error => {
        this.new_pct = 0
        this.loading = false
        this.notifError(error)
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('hosting/' + HOSTINGS_REQUEST, to.params.world).then(hostings => {
      next(vm => {
        vm.hostings = {...hostings}
      })
    })
  }
}
</script>

<style scoped lang="scss">
.form-new-space {
  max-width: 100%;
  width: 400px;
  button,
  input {
    font-size: 1.5em !important;
  }
}
.line {
  .badge {
    height: 42px;
    width: 42px;
    position: absolute;
  }
}
.progress {
  max-width: 100%;
  width: 600px;
}
</style>
